.main-section:nth-child(odd) {
    background-color: #ececec;
}

.sectionTitle {
    font-family: "Quicksand", sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 3rem;
}

.sectionDesc {
    font-family: "Quicksand", sans-serif;
    font-size: 1.2rem;
}

hr {
    width: 275px;
    height: 3px;
    border: 0;
    background-color: #0cb4b4;
    margin-bottom: 2.25rem;
}

.row {
    margin: auto;
}