.technologies {
    display: flex;
    justify-content: space-around;
    width: 75%;
    margin: 0 auto 5rem;
    padding: 1.5rem 0;
    border-radius: 10px;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    background-color: white;
    box-shadow: 0 19px 35px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.1);
}

.tech-icon {
    margin-bottom: 2rem;
    font-size: 2.5rem;
}

.front-end-tech,
.back-end-tech {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    padding: 2rem;
}
.front-end-tech {
    border-right: 1px solid lightgray;
}

.front-end-tech h2,
.back-end-tech h2 {
    margin-bottom: 2rem;
}

.front-end-tech h2 {
    cursor: pointer;
}

.front-end-tech p,
.back-end-tech p{
    min-height: 75px;
}

.front-end-tech h3,
.back-end-tech h3 {
    font-size: 1.5rem;
    margin: 1rem 0 0.5rem;
}

.front-end-tech ul,
.back-end-tech ul {
    list-style-type: none;
    padding: 0;
}

.front-end-tech li,
.back-end-tech li {
    font-family: "Quicksand", sans-serif;
    padding: 0.25rem 0;
}


@media screen and (max-width: 900px) {
    .technologies {
        flex-direction: column;
    }
    .front-end-tech {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid lightgray;
    }
    .back-end-tech {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .technologies {
        width: 90%;
    }
    .front-end-tech,
    .back-end-tech {
        padding: 1rem;
    }
}