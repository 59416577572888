nav {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Quicksand", sans-serif;
    padding: 2rem 3rem;
    animation: 1.5s fadein 1s forwards;
    opacity: 0;
    z-index: 1;
}
@keyframes fadein {
    100% {
        opacity: 1;
    }
}

.brandName {
    color: black;
    font-weight: 600;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
    transition: 0.2s all ease-in;
    font-size: 1.5rem;
}

.nav-list {
    margin-bottom: 0;
    padding: 0;
}

nav li {
    display: inline;
}

.nav-link {
    display: inline;
    color: black;
    font-size: 1rem;
    font-weight: 600;
    padding: 0 0 5px;
    margin: 0 0.75rem;
    border-bottom: 2px solid transparent;
    transition: 0.2s all ease-in;
}
.nav-link:hover,
.brandName:hover {
    color: black;
    text-decoration: none;
    padding-bottom: 5px;
    border-bottom: 2px solid #0cb4b4;
    cursor: pointer;
}

@media screen and (min-width: 1400px) {
    .brandName {
        font-size: 1.75rem;
    }
    .nav-link {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 700px) {
    nav {
        flex-direction: column;
    }
    nav li:first-child {
        margin-bottom: 0.50rem;
    }
    .brandName, .nav-link {
        font-weight: 500;
    }
}

