.hero {
    position: relative;
    max-width: 100%;
    height: 100vh;
    border: 1px solid white;
}

.hero .background-image {
    /* background-image: url(../assets/main.jpg); */
    background-image: url(../assets/banner-img.jpg);
    background-size: cover;
    background-position: center;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100vh;
    z-index: -1;
}

.hero-content-area {
    font-family: "Quicksand", sans-serif;
    height: 220px;
    width: 90%;
    margin: 400px auto 0;
    text-align: center;
    color: black;
    opacity: 0;
    animation: 1s slidefade 1s forwards;
}

@keyframes slidefade {
    100% {
        opacity: 1;
        margin: 300px auto 0;
    }
}

@media only screen and (max-width: 1500px) {
    @keyframes slidefade {
        100% {
            opacity: 1;
            margin: 200px auto 0;
        }
    }
}

.hero-content-area h1 {
    font-size: 3.20rem;
    margin-bottom: 1.25rem;
}

.hero-content-area h3 {
    font-size: 2rem;
    margin-bottom: 2.75rem;
}

.hero-content-area a {
    border: 1px solid #0cb4b4;
    background-color: #0cb4b4;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border-radius: 2px;
    text-decoration: none;
}

@media only screen and (max-width: 1000px) {
    .hero-content-area h1 {
        font-size: 2.50rem;
    }
}

@media only screen and (min-width: 1500px) {
    .hero-content-area h1 {
        font-size: 3.5rem;
    }
    .hero-content-area h3 {
        font-size: 3rem;
    }
}

@media only screen and (max-width: 500px) {
    .hero .background-image {
        background-position: 60% 75%;
    }
}