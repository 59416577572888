#about {
    padding-bottom: 75px;
}

.aboutContent {
    padding: 0 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.aboutDesc {
    width: 65%;
    margin-left: 2rem;
}
.aboutDesc p {
    margin: 0;
    font-size: 1.10rem;
    font-family: "Quicksand", sans-serif;
    line-height: 2;
}

.aboutPic {
    width: 325px;
    border-radius: 25px;
}

@media screen and (max-width: 950px) {
    .aboutContent {
        padding: 0 3rem;
        flex-direction: column;
        align-items: center;
    }
    .aboutDesc {
        margin: 2rem;
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .aboutContent {
        padding: 0 0.75rem;
    }
}