body {
  margin: 0;
  background-color: #FFFFFF;
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
