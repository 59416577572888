#portfolio {
    padding-bottom: 1.5rem;
}

.card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.card {
    width: 40vw;
    margin: 0 1.25rem 4rem;
    border-radius: 4px;
    box-shadow: 0 19px 35px rgba(0, 0, 0, 0.2), 0 15px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s linear;
}
.card:hover {
    box-shadow: 0 19px 35px rgba(0, 0, 0, 0.4), 0 15px 12px rgba(0, 0, 0, 0.1);
}

@media (max-width: 850px){
    .card {
        width: 75vw;
    }
}

@media (max-width: 600px){
    .card {
        width: 90vw;
    }
}

.card img {
    border-bottom: 1px solid #ECECEC;
}

.card-body {
    color: black;
    background-color: white;
}

.card-text {
    color: #2A2E35;
}

.app-link {
    border: 1px solid #0cb4b4;
    background-color: #0cb4b4;
    color: #fff;
    border-radius: 3px;
    padding: 3px 7px;
    margin-right: 5px;
    
}
.app-link:hover {
    color: #fff;
    text-decoration: none;
}
.app-link.disabled-link {
    pointer-events: none;
    cursor: default;
    background-color: #bdc3c7;
    border-color: #bdc3c7;
    color: rgba(255, 255, 255, 0.75);
    opacity: 0.7;
}

.repo-link {
    color: black;
    border: 1px solid #ececec;
    background-color: #ececec;
    border-radius: 3px;
    padding: 3px 5px;
}
.repo-link:hover {
    color: black;
    text-decoration: none;
}

