.App {
    margin: 0 auto;
    position: relative;
    max-width: 2000px;
}

/* Landscape View */
@media only screen and (max-height: 400px) and (orientation: landscape) { 
    nav {
        flex-direction: row;
        padding: 2.5rem 0.5rem;
    }
    nav li {
        margin-right: 0.75rem;
    }

    .hero-content-area {
        margin-top: 3.25rem;
        animation: 1s slidefade 1s forwards;
    }
    @keyframes slidefade {
        100% {
            opacity: 1;
            margin-top: 6.25rem;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .hero-content-area h1 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
    
    .hero-content-area h3 {
        font-size: 1.25rem;
        margin-bottom: 2rem;
    }
    
    .hero-content-area a {
        padding: 0.75rem 0.75rem;
    }

}
